import React, {useState, useSelector, useEffect} from "react";
import PlatformSettings from "../PlatformSettings.jsx";
import $api from "../../http/api";

export default function AddFrameKI({openModal}) {
    const [integrationData, setIntegrationData] = useState({
        groupsCards: [],
        groupsKI: []
    });
    const [selectIntegration, setSelectIntegration] = useState({});
    const [isActivePlatformSettings, setIsActivePlatformSettings] = useState(false);

    useEffect (() => {
        const objData = {
            groupsCards: [],
            groupsKI: []
        }
        Promise.all([
            $api.get('/getKIGroups').then(result => { // получаем список групп обучения КИ
                result.data.groups.forEach(row => {
                    objData.groupsKI.push({  //пушим список групп
                    "group_id": row.id, // id группы обучения
                    "group_name": row.name, // название группы
                    "kurs_id": row.kurs_id // курс который связан с группой
                  });
                });
              }),
              $api.post("/getGroupsCards").then((result) => {
                objData.groupsCards = result.data.groups_card})
        ])
        .then(() => {
            setIntegrationData(objData);
            setSelectIntegration({
                groupCards: objData.groupsCards[0].group_id,
                groupKI: objData.groupsKI[0].group_id
            })
        })
    }, []);

    useEffect(() => {
        console.log("integrationData", integrationData);
    }, [integrationData]);

    useEffect(() => {
        setIsActivePlatformSettings(false);
        setSelectIntegration({});
    }, [openModal]);

    const handleAddIntegration = () => { 
        console.log(selectIntegration.groupCards,selectIntegration.groupKI);
        if (selectIntegration.groupCards && selectIntegration.groupKI) {
            setIsActivePlatformSettings(true);
        }
    }

    return (
        !isActivePlatformSettings ? (
            <>
        <div>
            <label>Группа карточек</label>
             <select name="groupCards" required onChange={e => setSelectIntegration({...selectIntegration, groupCards: e.target.value})}>
                {integrationData.groupsCards.map(group => (
                <option key={group.group_id} selected={selectIntegration.groupCards == group.group_id} value={group.group_id}>{group.group_name}</option>
                ))}
            </select>
        </div>
        <div>
            <label>Группа курса</label>
            <select name="groupKI" required onChange={e => setSelectIntegration({...selectIntegration, groupKI: e.target.value})}>
                {integrationData.groupsKI.map(group => (
                <option key={group.group_id} selected={selectIntegration.groupKI == group.group_id} value={group.group_id}>{group.group_name}</option>
                ))}
            </select>
        </div>
        <button onClick={() => handleAddIntegration()}>Добавить</button>
        </>
        ) : (
            <PlatformSettings groupId={selectIntegration.groupCards} kiGroupId={selectIntegration.groupKI} />
        )
    )
}