import React, { useState, useRef } from "react";
import $api from "../http/api";


function AddCardForm({ groupId }) {

  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewAudio, setPreviewAudio] = useState(null);
  const imgInputRef = useRef(null);

  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const imageRef = useRef(null);
  const audioRef = useRef(null);
  const wrapperRef = useRef(null);
  const [bounds, setBounds] = useState({});

  const [formData, setFormData] = useState({
      word: '',
      translate: '',
      transcription: '',
      groupId: groupId,
      img: "https://pic.rutubelist.ru/video/37/da/37da33522ccfda271da087e229ae17e6.jpg",
      sound: "/null.mp3"
    });


    const startDrag = (e) => {
      e.preventDefault();
      setDragging(true);
    };

    const onDrag = (e) => {
      if (!dragging) return;
      const deltaX = e.movementX;
      const deltaY = e.movementY;
    
      let withinHorizontalBounds, withinVerticalBounds;
      const newX = position.x + deltaX;
      if (bounds.image.width <= bounds.wrapper.width) {
        withinHorizontalBounds = bounds.image.left + deltaX > bounds.wrapper.left && bounds.image.right + deltaX < bounds.wrapper.right;
      }
      else {
        withinHorizontalBounds = bounds.image.left + deltaX < bounds.wrapper.left && bounds.image.right + deltaX > bounds.wrapper.right;
      }
      const x = withinHorizontalBounds ? newX : position.x;

    
      const newY = position.y + deltaY;
       if (bounds.image.top > bounds.wrapper.top && bounds.image.bottom < bounds.wrapper.bottom) {
        withinVerticalBounds = bounds.image.top + deltaY > bounds.wrapper.top && bounds.image.bottom + deltaY < bounds.wrapper.bottom;
      }
      else {
        if (bounds.image.top + deltaY > bounds.image.top) {
          withinVerticalBounds = bounds.image.top + deltaY < bounds.wrapper.top;
        }
        else {
          withinVerticalBounds = bounds.image.bottom + deltaY > bounds.wrapper.bottom;
        } 
      }
      const y = withinVerticalBounds ? newY : position.y;
      setPosition({ x, y });
    };
    
  
    const stopDrag = () => {
      setDragging(false);
    };

    const handleWheel = (e) => {
      e.preventDefault();
      const direction = e.deltaY < 0 ? 1 : -1;
      const newScale = scale + direction * 0.1;
      setScale(Math.min(Math.max(newScale, 0.5), 4));
    };

    function getBounds() {
      const wrapper = wrapperRef?.current;
      const image = imageRef?.current;
  
      if (wrapper && image) {
        const wrapperBounds = wrapper.getBoundingClientRect();
        const imageBounds = image.getBoundingClientRect();
  
        return {
          wrapper: wrapperBounds,
          image: imageBounds,
        };
      } else {
        return null;
      }
    }

React.useEffect(() => {
  const imageElement = imageRef.current;
  if (!imageElement) return;
  setScale(1);
  setPosition({ x: 0, y: 0 });
  imageElement.addEventListener('wheel', handleWheel, { passive: false });
}, [previewUrl]);

  React.useEffect(() => {
    setBounds(getBounds());
  }, [position, scale]);

    React.useEffect(() => {
      const imageElement = imageRef.current;
      if (!imageElement) return;
      imageElement.addEventListener('wheel', handleWheel, { passive: false });
  
      return () => {
        if (imageElement) { 
          imageElement.removeEventListener('wheel', handleWheel);
        }
      };
    }, [scale]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleImgChange = (e) => {
    const selectedImg = e.target.files[0];
    if (selectedImg) {
      setFormData((prevData) => ({
        ...prevData,
        img: selectedImg,
      }));
      setPreviewUrl(URL.createObjectURL(selectedImg));
    }
  };

  const handleAudioChange = (e) => {
    console.log("handleAudioChange");
    const selectedAudio = e.target.files[0];
    if (selectedAudio) {
      setFormData((prevData) => ({
        ...prevData,
        sound: selectedAudio,
      }));
      setPreviewAudio(URL.createObjectURL(selectedAudio));
    }
  };

  const handleImgButtonClick = () => {
    imgInputRef.current?.click();
  };

  const handleAudioButtonClick = () => {
    audioRef.current?.click();
  };

  React.useEffect(() => {
    console.log("FormData from AddCard ", formData);
  }, [formData]);

  const clickPlayPrevewAudio = () => {
    if (!previewAudio) return;
    const audio = new Audio;
    audio.src = previewAudio;
    audio.play();
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    $api.post(`/addCard/${groupId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      })
      .then((response) => {
        console.log('Успешно отправлено:', response.data);
      })
      .catch((error) => {
        console.error('Ошибка при отправке данных:', error);
      });
  };

  return (
    <div className="card" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="left-side" style={{ position: "relative" }}>
                    <div className="card--content" style={{width: "100%"}}>
                        <div className="card-img--wrapper">
                            <div className="card-img--content" ref={wrapperRef} style={{ backgroundImage: "url('https://pic.rutubelist.ru/video/37/da/37da33522ccfda271da087e229ae17e6.jpg')", overflow: "hidden"}}>
                            {previewUrl && (
      <div>
      <img alt="preview" ref={imageRef} src={previewUrl} style={{
        height: "auto", 
        width: "100%", 
        objectFit: "cover",
        top: (position.y || 0) + "px", 
        left: (position.x || 0) + "px",
        position: "relative",
        transform: `scale(${scale})`,
        transformOrigin: 'center center'
      }} onMouseDown={startDrag}
      onMouseMove={onDrag}
      onMouseUp={stopDrag}
      onMouseLeave={stopDrag}
      draggable={false}
      />
    </div>
      )}
                            </div>
                        </div>
                        <div className="card-information">
                            <div className="word"><span>{formData.word || "Слово"}</span></div>
                            <div className="transcription"><span>{formData.transcription || "Транскрипция"}</span></div>
                            <div className="word-sound">
                                <i className='bx bx-volume-full' onClick={clickPlayPrevewAudio}></i>
                            </div>
                            <div className="translate"><span>{formData.translate || "Перевод"}</span></div>
                        </div>
                    </div>
                        </div>
                        <div className="right-side" style={{ flexBasis: "0%" }}></div>
    <form onSubmit={handleSubmit}>
        <div className="form--item">         
          <label>Картинка</label>
          <button onClick={handleImgButtonClick} type="button">Загрузить картинку</button>
          <input
            type="file" name="img" style={{ display: "none" }} ref={imgInputRef} onChange={handleImgChange} accept="image/*,image/jpeg"
          />
      </div>
      <div className="form--item">
        <label>Слово на иврите</label>
        <input
          type="text" name="word" value={formData.word}onChange={handleChange} required
        />
      </div>
      <div className="form--item">
        <label>Перевод</label>
        <input
          type="text" name="translate" value={formData.translate} onChange={handleChange} required
        />
      </div>
      <div className="form--item">
        <label>Транскрипция</label>
        <input type="text" name="transcription" value={formData.transcription} onChange={handleChange} required
        />
      </div>
      <div className="form--item">         
          <label>Аудио</label>
          <button onClick={handleAudioButtonClick} type="button">Загрузить озвучку</button>
          <input
            type="file" name="audio" style={{ display: "none" }} ref={audioRef} onChange={handleAudioChange} accept="audio/*,audio/mp3,audio/waw"
          />
      </div>
      <button type="submit">Добавить</button>
    </form>
    </div>
  );
}

function AddCard({ id, open }) {
  const [openModal, setOpenModal] = React.useState(open);

  const toggleModal = () => {
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    setOpenModal(open);
  }, [open])

  return (
    <div className={openModal ? `modal-block visible` : `modal-block`}>
      <div className="popup--wrapper">
        <div className="popup--content">
          <div className="popup--header">
            <h3>Добавить карточку</h3>
            <i className='bx bx-x' onClick={() => toggleModal()}></i>
          </div>
            <AddCardForm groupId={id} />
        </div>
      </div>
    </div>
  );
}

export default AddCard;
