import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CheckUserRole = function (WrappedComponent, {isPage = false}, allowedRoles = []) {
    return function CheckUserRoleWrapper(props) {
        const user = useSelector(state => state.user);
        const navigate = useNavigate();

        useEffect(() => {
            const isAuth = user.isAuth;
            const role = user.userData.role;

            if (!isAuth) {
                console.log("NOT AUTH");
                isPage && navigate("/login");
            } else if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
                console.log("ROLE NOT ALLOWED");
                isPage && navigate("/login");
            }
        }, [user, navigate, isPage, allowedRoles]); 

        if (!user.isAuth || (allowedRoles.length > 0 && !allowedRoles.includes(user.userData.role))) {
            return isPage ? null : null;
        }

        return <WrappedComponent {...props} />;
    };
};

export default CheckUserRole;
