import React, {useState} from "react";
import {Link} from 'react-router-dom';
import {GroupsCard} from "../components/";
import CheckUserRole from "../components/service-components/CheckUserRole";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import $api from '../http/api';
import {setGroupsCards} from '../redux/actions/cards.js';

function Home() {
    return (
        <div className="group-cards">
            <div className="group-cards--wrapper">
                <div className="group-cards--content">
                    <div className="title"><span>Новые наборы карточек</span></div>
                    <GroupsCard />
                </div>
            </div>
        </div>
    );
}

export default CheckUserRole(Home, { isPage: true }, ["user", "admin"]);