import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { addNewGroup } from "../redux/actions/cards";
import $api from "../http/api";

function AddGroupForm() {
    const dispatch = useDispatch();  
    const [formData, setFormData] = useState({
        group_name:"",
        cards: [],
        group_description: "",
        level: "alef",
        type:"platform",
        cards: [],
        visible: "private",
        platform: ""
    });
    const [previewUrl, setPreviewUrl] = useState(null);
    const imgInputRef = useRef(null);
    const formRef = useRef(null);
    const [formMessage, setformMessage] = useState('');

    // Сохраняем значения полей ввода
    const handleChange = (e) => {
        let name, value;
        if (e.target.type === 'select-multiple') {
            value = Array.from(e.target.selectedOptions, option => option.value);
        }
        else{
            value = e.target.value;
        }
        name = e.target.name;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }; 
    
      // Получаем из загруженной картинки превью
      const handleImgChange = (e) => {
        const selectedImg = e.target.files[0];
        if (selectedImg) {
          setFormData((prevData) => ({
            ...prevData,
            img: selectedImg,
          }));
          setPreviewUrl(URL.createObjectURL(selectedImg));
        }
      };

      // Вызываем input для выбора картинки
      const handleImgButtonClick = () => {
        imgInputRef.current?.click();
      };

      // Логируем formData
      React.useEffect(() => {
        console.log("FormData from AddCard ", formData);
      }, [formData]);

      // Отправляем данные формы
      const handleSubmit = (e) => {
        e.preventDefault();
        $api.post(`/addGroupCards`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            }
          })
          .then((response) => {
            console.log('Успешно отправлено:', response.data);
            dispatch(addNewGroup(response.data.new_group));
            setformMessage("Набор успешно добавлен");
          })
          .catch((error) => {
            console.error('Ошибка при отправке данных:', error);
          });
      };

    return (
        <>
        <div className="left-side" style={{ position: "relative" }}>
            <div className="card--content" style={{width: "100%"}}>
                <div className="card-img--wrapper">
                    <div className="card-img--content" style={{ backgroundImage: "url('https://pic.rutubelist.ru/video/37/da/37da33522ccfda271da087e229ae17e6.jpg')", overflow: "hidden"}}>
                    {previewUrl && (
                        <div>
                            <img alt="preview" src={previewUrl} style={{
                            height: "auto", 
                            width: "100%", 
                            objectFit: "cover",
                            }}
                            />
                        </div>
                    )}
                    </div>
                </div>
                <button onClick={handleImgButtonClick} type="button" class="load">Загрузить картинку</button>
            </div>
        </div>
        <div className="right-side" ref={formRef}>
            <form onSubmit={handleSubmit}>
                <div className="form--item">         
                    <input
                    type="file" name="img" style={{ display: "none" }} ref={imgInputRef} onChange={handleImgChange} accept="image/*,image/jpeg"
                    />
                </div>
                <div className="form--item">
                    <label>Название</label>
                    <input
                    type="text" name="group_name" value={formData.group_name} onChange={handleChange} required
                    />
                </div>
                <div className="form--item">
                    <label>Описание</label>
                    <input
                    type="text" name="group_description" value={formData.group_description} onChange={handleChange} required
                    />
                </div>
                <div className="form--item">
                    <label>Кто может видеть набор</label>
                    <select name="visible" onChange={handleChange} required >
                        <option value="private">Только я</option>
                        <option value="public">Все пользователи</option>
                    </select>
                </div>
                <div className="form--item">
                    <label>Доступность</label>
                    <select name="platform" onChange={handleChange} required >
                        <option value="ki">Платформа КИ</option>
                        <option value="quizlet">Сайт Квизлет</option>
                        <option value="ki-quizlet">Платформа КИ и сайт Квизлет</option>
                    </select>
                </div>
                <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                  <button type="submit">Создать набор</button>
                  <div className="form--message">{formMessage}</div>
                </div>
            </form>
        </div>
        </>
    );

}


export default AddGroupForm;