import React, {useEffect, useState} from "react";
import BlockTraining from '../components/BlockTraining.jsx';
import axios from "axios";
import {Link} from "react-router-dom";
import $api from '../http/api.js';
import { useLocation } from 'react-router-dom';
import {userLogin} from '../redux/actions/user.js';
import { useSelector, useDispatch } from 'react-redux';
import {updateTrainingStat} from '../redux/actions/stat-cards.js';


function FrameTraining() {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const key = searchParams.get('key');
    let type = searchParams.get('type');

    const typesTraining = [
        {title: "Слово - перевод", type: "word-translation"},
        {title: "Перевод - слово", type: "translation-word"}
    ];

    const firstType = typesTraining.find(el => el.type === type);
    const secondType = typesTraining.find(el => el.type !== type);
    
    const [activeTraining, setActiveTraining] = useState(type);
    const [isChangeType, setIsChangeType] = useState(false);
    const [cardsInfo, setCardsInfo] = useState([]);
    const [groupInfo, setGroupInfo] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [incorrectAnswerGiven, setIncorrectAnswerGiven] = useState(true);
    const [resultTraining, setResultTraining] = useState([]);

    const [trainingState, setTrainingState] = useState({
        currentCard: 0,
        isUserChoiceMade: false
    });
    const currentCard = trainingState.currentCard;

    useEffect(() => {
        setActiveTraining(typesTraining.find(el => el.type === type).type);
    }, [])

    async function authUserFromKI(){
        const userData = await $api.post("/user/authUserFromKI", {key}).then((response) => response.data);
        console.log("userData from FrameTraining", userData);
        localStorage.setItem('token', userData.accessToken);
        localStorage.setItem('userData', JSON.stringify(userData.user));
        dispatch(userLogin(userData.user));
    }

    React.useEffect(() => {
        $api.get(`/getCardsGroup/${id}`).then((result) => {
            setCardsInfo(result.data.cardsList.slice(0,3).sort(()=>0.5 - Math.random()));
            setGroupInfo(result.data.groupInfo);
            console.log("cardsInfo ", cardsInfo);
            setAnswers(result.data.cardsList.slice(0,3).map((obj) => {
                let answer;
                switch (type) {
                    case "word-translation":
                        answer = obj.translate;
                        break;
                    case "translation-word":
                        answer = obj.word;
                        break;
                    default:
                        answer = "";
                }
                return answer;
            })); 
        });
    }, [type]);

    React.useEffect(() => {
        console.log("Проверяем CardsInfo", cardsInfo);
    },[cardsInfo] )


    const handleNextQuestion = () => {
/*         if(!incorrectAnswerGiven && currentCard < cardsInfo.length) {
            setCurrentCard(currentCard + 1);
            next_question = cardsInfo[currentCard+1];
            setIncorrectAnswerGiven(true);
        }
        else {
            setCurrentCard(currentCard);
            next_question = "end";
        }   */  
    }

    const handleTypeTraining = (type, cards, currentCard) => {
        setResultTraining([]);
        if (currentCard == 0) {
            setActiveTraining(type);
            setAnswers(cards.map((obj) => {
                let answer;
                switch (type) {
                    case "word-translation":
                        answer = obj.translate;
                        break;
                    case "translation-word":
                        answer = obj.word;
                        break;
                    default:
                        answer = "";
                }
                return answer;
            })); 
        }
    }
    const changeQuestion = React.useCallback((answer) => {
        if (answer) {
            console.log("answer from Training", answer);
            setTimeout(() => {
                setResultTraining(prevState => (
                    [...prevState, answer]));
                    console.log("resultTraining ", resultTraining);
                dispatch(updateTrainingStat(answer));
                setTrainingState(prevState => ({
                    ...prevState,
                    currentCard: prevState.currentCard + 1,
                    isUserChoiceMade: true
                }));
            }, 1000);
        }
    },[trainingState]);

    return (
        <>
        <div className="block-cards" style={{backgroundColor: "#FFFFFF", display: "flex", justifyContent: "center"}} tabIndex={0} onKeyDown={handleNextQuestion}>
        <div className="card--wrapper embed">
            <div className="cards-info">
                <div className="title">Набор: <span>{groupInfo.group_name}</span></div>
                <div style={{display:"none"}}>{localStorage.getItem('userData')}</div>
                <div className="count-cards">
                    <div className="count-cards--content"><span name="count_done">{currentCard < cardsInfo.length ? currentCard+1 : currentCard}</span>/<span name="count_all">{cardsInfo.length}</span></div>
                </div>
            </div>
            {currentCard < cardsInfo.length ? (
                <>
            {/* {currentCard == 0 && (<div style={{fontSize: "18px", marginTop: "7px", textAlign: "center"}}>Выберите тип тренировки:</div>)} */}
            <div className="type-training">
                <span onClick = {() => handleTypeTraining(firstType?.type, cardsInfo, currentCard)} className={activeTraining == firstType?.type ? "active" : ""} style={activeTraining != firstType?.type && (currentCard > 0 || isChangeType) ? {display: "none"} : {}}>{firstType?.title}</span>
                <span onClick = {() => handleTypeTraining(secondType?.type, cardsInfo, currentCard)} className={activeTraining == secondType?.type ? "active" : ""} style={activeTraining != secondType?.type && (currentCard > 0 || isChangeType) ? {display: "none"} : {}}>{secondType?.title}</span>
            </div>
            <BlockTraining cardsData = {cardsInfo.slice(currentCard, currentCard + 2)} answers={answers}  isUserChoiceMade={trainingState.isUserChoiceMade} type={activeTraining} changeQuestion={changeQuestion}/>
            </>
            ) : currentCard ? (
                <div className="result embed">
                    <h3>Ваш результат:</h3>
                    <span>{`Из ${cardsInfo.length} карточек вы правильно отметили ${resultTraining.filter(answer=>answer.isCorrectAnswer===true) ? resultTraining.filter(answer=>answer.isCorrectAnswer===true).length : 0}`}</span>
                    <div className="result--list">
                        <div className="wrong-items">{
                        resultTraining.filter(answer=>answer.isCorrectAnswer!==true).map((item) => 
                        ( <div className="correct-item">
                            <div>{item.card["word"]}</div>
                            <i className='bx bx-volume-full'></i>
                            <div>{"[" + item.card["transcription"] + "]"}</div>
                            <div>{item.card["translate"]}</div>
                          </div>
                        ))}</div>
                        <div className="correct-items">{
                        resultTraining.filter(answer=>answer.isCorrectAnswer===true).map((item) => 
                        ( <div className="correct-item">
                            <div>{item.card["word"]}</div>
                            <i className='bx bx-volume-full'></i>
                            <div>{"[" + item.card["transcription"]  + "]"}</div>
                            <div>{item.card["translate"]}</div>
                        </div>
                        ))}</div>
                    </div>
                    <div className="btn--list--wrapper">
                        <div onClick={()=> {setTrainingState({currentCard: 0,isUserChoiceMade: false});setResultTraining([]);setIsChangeType(false)}} className="btn">Попробовать снова</div>
                        <div onClick={()=> {setTrainingState({currentCard: 0,isUserChoiceMade: false});setIsChangeType(true);handleTypeTraining(secondType?.type, cardsInfo, 0)}} className="btn">Сменить тренировку</div>
                    </div>
                </div>
            ) : (
                    <div className="result">
                        <span>Загрузка...</span>
                    </div>
                )}
        </div>
    </div>
    </>
    )
}

export default FrameTraining;