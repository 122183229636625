import React from "react";
import TrainingOptions from './TrainingOptions.jsx';
import CardInfo from './CardInfo.jsx';
import BlockTraining from './BlockTraining.jsx';
import axios from "axios";
import $api from '../http/api.js';
import {Link} from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {updateTrainingStat} from '../redux/actions/stat-cards.js';


function Training({type}) {
    const dispatch = useDispatch();
    const stat = useSelector(state => state.statCards);
    console.log("stat ", stat);
    const {id} = useParams();
    const [cardsInfo, setCardsInfo] = React.useState([]);
    const [groupInfo, setGroupInfo] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);
    const [incorrectAnswerGiven, setIncorrectAnswerGiven] = React.useState(true);
    const [resultTraining, setResultTraining] = React.useState([]);
    const [statCards, setStatCards] = React.useState([]);

    const [trainingState, setTrainingState] = React.useState({
        currentCard: 0,
        isUserChoiceMade: false
    });
    const currentCard = trainingState.currentCard;

    React.useEffect(() => {
        $api.get(`/getCardsGroup/${id}`).then((result) => {
            setGroupInfo(result.data.groupInfo);
            setCardsInfo(result.data.cardsList);
            console.log("cardsInfo ", cardsInfo);
            setAnswers(result.data.cardsList.map((obj) => {
                let answer;
                switch (type) {
                    case "word-translation":
                        answer = obj.translate;
                        break;
                    case "translation-word":
                        answer = obj.word;
                        break;
                    default:
                        answer = "";
                }
                return answer;
            })); 
        });
    }, []);

    React.useEffect(() => {
        console.log("Проверяем CardsInfo", cardsInfo);
    },[cardsInfo] )

    const handleNextQuestion = () => {
        if(!incorrectAnswerGiven && currentCard < cardsInfo.length) {
            //setCurrentCard(currentCard + 1);
            //next_question = cardsInfo[currentCard+1];
            //setIncorrectAnswerGiven(true);
        }
        else {
            //setCurrentCard(currentCard);
            //next_question = "end";
        }    
    }

    const changeQuestion = React.useCallback((answer) => {
        if (answer) {
            const {card, ...statCard} = answer;
            console.log("answer", answer);
            statCard.card_id = card.card_id;

            setTimeout(() => {
                setStatCards(prev => [...prev, statCard]);
                setResultTraining(prevState => (
                    [...prevState, answer]));
                dispatch(updateTrainingStat(answer));
                setTrainingState(prevState => ({
                    ...prevState,
                    currentCard: prevState.currentCard + 1,
                    isUserChoiceMade: true
                }));
            }, 1000);
        }
    },[trainingState]);

    React.useEffect(() => {
        if (currentCard > 0 && currentCard === cardsInfo.length){
            console.log("currentCard", currentCard, "cardsInfo.length", cardsInfo.length);
            const statData = {"stat_training": statCards};
            console.log("statData", statData);
            $api.post('/user/setUserStatCards', statData).then((response) => {
                console.log('Успешно отправлено:', response.data);
            });
        }
    }, [currentCard]);

    return (
        <div className="block-cards" tabIndex={0} onKeyDown={handleNextQuestion}>
        <div className="card--wrapper">
            <div className="cards-info">
                <div className="title">Набор: <Link to={`/group/${id}`}><span>{groupInfo.group_name}</span></Link></div>
                <div className="count-cards">
                    <div className="count-cards--content"><span name="count_done">{currentCard < cardsInfo.length ? currentCard+1 : currentCard}</span>/<span name="count_all">{cardsInfo.length}</span></div>
                </div>
            </div>
            {currentCard < cardsInfo.length ? (
                <>
            <div className="type-training"><span>{type === "word-translation" ? "Слово-перевод" : "Перевод-слово"}</span></div>
            <BlockTraining cardsData = {cardsInfo.slice(currentCard, currentCard + 2)} answers={answers}  isUserChoiceMade={trainingState.isUserChoiceMade} type={type} changeQuestion={changeQuestion}/>
            </>
            ) : currentCard ? (
                <div className="result">
                    <h3>Ваш результат:</h3>
                    <span>{`Из ${cardsInfo.length} карточек вы правильно отметили ${resultTraining.filter(answer=>answer.isCorrectAnswer===true) ? resultTraining.filter(answer=>answer.isCorrectAnswer===true).length : 0}`}</span>
                    <div className="result--list">
                        <div className="wrong-items">{
                        resultTraining.filter(answer=>answer.isCorrectAnswer!==true).map((item) => 
                        ( <div className="correct-item">
                            <div>{item.card["word"]}</div>
                            <i className='bx bx-volume-full'></i>
                            <div>{"[" + item.card["transcription"] + "]"}</div>
                            <div>{item.card["translate"]}</div>
                          </div>
                        ))}</div>
                        <div className="correct-items">{
                        resultTraining.filter(answer=>answer.isCorrectAnswer===true).map((item) => 
                        ( <div className="correct-item">
                            <div>{item.card["word"]}</div>
                            <i className='bx bx-volume-full'></i>
                            <div>{"[" + item.card["transcription"] + "]"}</div>
                            <div>{item.card["translate"]}</div>
                        </div>
                        ))}</div>
                    </div>
                </div>
            ) : (
                    <div className="result">
                        <span>Загрузка...</span>
                    </div>
                )}
        </div>
    </div>
    )
}

export default Training;