import React, { useState, useRef } from "react";
import $api from "../http/api";
import {updateGroupInfo} from '../redux/actions/cards.js';
import {useSelector, useDispatch} from 'react-redux';

function EditGroupForm({groupInfo}) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(groupInfo);
    const [previewUrl, setPreviewUrl] = useState(groupInfo.img);
    const imgInputRef = useRef(null);
    const [formMessage, setformMessage] = useState('');

    // Сохраняем значения полей ввода
    const handleChange = (e) => {
        let name, value;
        if (e.target.type === 'select-multiple') {
            value = Array.from(e.target.selectedOptions, option => option.value);
        }
        else{
            value = e.target.value;
        }
        name = e.target.name;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }; 
    
      // Получаем из загруженной картинки превью
      const handleImgChange = (e) => {
        const selectedImg = e.target.files[0];
        if (selectedImg) {
          setFormData((prevData) => ({
            ...prevData,
            img: selectedImg,
          }));
          setPreviewUrl(URL.createObjectURL(selectedImg));
        }
      };

      // Вызываем input для выбора картинки
      const handleImgButtonClick = () => {
        imgInputRef.current?.click();
      };

      // Логируем formData
      React.useEffect(() => {
        console.log("FormData from AddCard ", formData);
      }, [formData]);

      React.useEffect(() => {
        setFormData(groupInfo);
        delete formData.cards;
        console.log("formData", formData);
      }, [groupInfo]);

      // Отправляем данные формы
      const handleSubmit = (e) => {
        e.preventDefault();
        const formdata = new FormData();
        for (let key in formData) {
            formdata.append(key, formData[key]);
        }
        $api.post(`/editGroupCards`, formdata)
          .then((response) => {
            console.log('Успешно отправлено:', response.data);
            dispatch(updateGroupInfo(response.data.groupInfo));
            setformMessage("Изменения сохранены");
          })
          .catch((error) => {
            console.error('Ошибка при отправке данных:', error);
          });
      };
    return (
        <>
        <div className="left-side" style={{ position: "relative" }}>
            <div className="card--content" style={{width: "100%"}}>
                <div className="card-img--wrapper">
                    <div className="card-img--content" style={{ backgroundImage: "url('https://pic.rutubelist.ru/video/37/da/37da33522ccfda271da087e229ae17e6.jpg')", overflow: "hidden"}}>
                    {previewUrl && (
                        <div>
                            <img alt="preview" src={previewUrl} style={{
                            height: "auto", 
                            width: "100%", 
                            objectFit: "cover",
                            }}
                            />
                        </div>
                    )}
                    </div>
                </div>
                <button onClick={handleImgButtonClick} type="button" class="load">Загрузить картинку</button>
            </div>
        </div>
        <div className="right-side">
            <form onSubmit={handleSubmit}>
                <div className="form--item">         
                    <input
                    type="file" name="img" style={{ display: "none" }} ref={imgInputRef} onChange={handleImgChange} accept="image/*,image/jpeg"
                    />
                </div>
                <div className="form--item">
                    <label>Название</label>
                    <input
                    type="text" name="group_name" value={formData.group_name} onChange={handleChange} required
                    />
                </div>
                <div className="form--item">
                    <label>Описание</label>
                    <input
                    type="text" name="group_description" value={formData.group_description} onChange={handleChange} required
                    />
                </div>
                <div className="form--item">
                    <label>Кто может видеть набор</label>
                    <select name="visible" onChange={handleChange} required >
                        <option value="private">Только я</option>
                        <option value="public">Все пользователи</option>
                    </select>
                </div>
                <div className="form--item">
                    <label>Доступность</label>
                    <select name="platform" onChange={handleChange} required >
                        <option value="ki">Платформа КИ</option>
                        <option value="quizlet">Сайт Квизлет</option>
                        <option value="ki-quizlet">Платформа КИ и сайт Квизлет</option>
                    </select>
                </div>
                <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                  <button type="submit">Сохранить изменения</button>
                  <div className="form--message">{formMessage}</div>
                </div>
            </form>
        </div>
        </>
    );

}


export default EditGroupForm;