import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import $api from "../../http/api";
import {useSelector, useDispatch} from 'react-redux';
import {useMessage} from "../service-components/MessageContext.jsx";
import PlatformSettings from "../PlatformSettings.jsx";
import {AddFrameKI} from "../admin";
import ModalPopup, {toggleModal} from "../ModalPopup.jsx";

export default function PlatformGroups() {
    const user = useSelector(state => state.user);

    const [KIGroupsData, setKIGroupssData] = useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [modalComponent, setModalComponent] = React.useState(null);

    useEffect(() => {
        async function fetchKIGroups() {
            try {
                const response = await $api.get(`/getKIFrames`);
                console.log("KIGroupData", response.data);
                setKIGroupssData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchKIGroups();
    }, []);
    

    return (
        <div className="content--wrapper">
            <ModalPopup openMod={openModal} toggleModal ={() => toggleModal(openModal, setOpenModal)} title={modalComponent?.title}>{modalComponent?.component}</ModalPopup>
            <div className="head">
                <div className="title"><span>Настройка интеграций</span></div>
                {(user.isAuth) && (
                        <div className="btn blue-btn" onClick={() => {setModalComponent({component: <AddFrameKI/>, title: 'Добавить интеграцию'});toggleModal(openModal, setOpenModal)}}>Добавить интеграцию</div>
                )}
            </div>
            <div className="panel"></div>
            <div className="list-head">
                <div style={{width: "5%"}}>#</div>
                    <div style={{width: "20%"}}>Набор</div>
                    <div style={{width: "30%"}}>Группа</div>
                    <div style={{width: "45%"}}>Темы</div>
                    <div style={{width: "15%"}}>Тренировка</div>
                    <div style={{width: "5%"}}></div>
                </div>
            <div className="platform-groups--list">
                {KIGroupsData?.frames?.map((frame) => (
                    <div key={frame.id}className="platform-group--item">
                        <div className="id">{frame.id}</div>
                        <div className="name" style={{width: "20%", textDecoration: "underline"}}><Link to={`/group/${frame.quizlet_group_card}`}>{KIGroupsData.groupsList[parseInt(frame.quizlet_group_card)].group_name}</Link></div>
                        <div className="ki-group" style={{width: "30%"}}>{frame.group_name}</div>
                        <div className="ki-lessons" style={{width: "45%"}}>{JSON.parse(frame.lessons).map(lesson => {
                            return KIGroupsData.themesData.find(theme => theme.theme_id == lesson).theme_name;
                        }).join(" | ")}</div>
                        <div className="training" style={{width: "15%"}}>{frame.type_training}</div>
                        <div style={{width: "5%"}} onClick={() => {setModalComponent({component: <PlatformSettings groupId={frame.quizlet_group_card} kiGroupId={frame.group_id} />, title: 'Настройка интеграции'});toggleModal(openModal, setOpenModal)}} class="edit-btn"><i class="bx bx-edit"></i></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

