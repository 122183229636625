import React, {useState} from "react";
import { Switch, Route, Routes, useLocation, Link } from 'react-router-dom';
import {AdminMenu, PlatformGroups} from "../../components/admin";

const menu = [{name: "Дашборд", link: "/admin-p"}, {name:"Наборы", link: "/admin-p/groupcards"}, {name: "Карточки", link: "/admin-p/cards"}, {name: "Пользователи", link: "/admin-p/users"}, {name: "Платформа", link: "/admin-p/platform"}]

export default function AdminPanel() {
    const currentPage = useLocation();
    console.log("currentPage.pathname", currentPage.pathname);
    const [navbarActiveItem, setNavbarActiveItem] = useState(currentPage.pathname);
    return (
            <body>
                <header></header>
                <div className="container">
                    <navbar>
                        <div className="navbar--wrapper">
                            {menu.map((item) => (
                                <Link to={item.link}><div key={item.name} className={`navbar--item ${item.link === navbarActiveItem ? `active` : ''}`} onClick={() => setNavbarActiveItem(item.link)}>{item.name}</div></Link>
                            ))}
                        </div>
                    </navbar>
                    <content>
                        <Routes>
                            <Route path="/admin-p/platform" element={<PlatformGroups/>}/>
                        </Routes>
                    </content>
                </div>
            </body>
    )
}