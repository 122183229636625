import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ModalPopup, {toggleModal} from "./ModalPopup.jsx";
import AddCardForm from "./AddCardForm.jsx";
import CardsList from "./CardsList.jsx";
import EditGroupForm from "./EditGroupForm.jsx";
import PlatformSettings from "./PlatformSettings.jsx";
import { useSelector, useDispatch } from 'react-redux';
import { setDictionary } from '../redux/actions/dictionary';
import $api from '../http/api.js';
import {useMessage} from '../components/service-components/MessageContext.jsx';

const GroupCards = () => {
    const user = useSelector(state => state.user);
    const stateGroupCards = useSelector(state => state.groupCards);

    const dispatch = useDispatch();
    const stateDictionary = useSelector((state) => state.dictionary);

    const {addMessage} = useMessage();

    console.log("stateGroupCards", stateGroupCards);
    const [cardsInfo, setCardsInfo] = React.useState(null);
    const [groupInfo, setGroupInfo] = React.useState(null);
    const[openModal, setOpenModal] = React.useState(false);
    const [modalComponent, setModalComponent] = React.useState(null);

    const {id} = useParams();

    React.useEffect(() => {
        $api.get(`/getCardsGroup/${id}`).then((result) => {
            setCardsInfo(result.data.cardsList);
            setGroupInfo(result.data.groupInfo);
        });
    }, [id]);

    React.useEffect(()=> {
        addMessage({type: "success", text: "Тестовое сообщение"});
        if (!stateDictionary.isLoaded) {
            $api.get("/getUserDictionary").then((result) => {
                dispatch(setDictionary(result.data));
            });
        }
    },[]);

    React.useEffect(() => {
        console.log("Изменился стейт groupCards", stateGroupCards);
        setGroupInfo(stateGroupCards.groups.find(group => group.group_id == id));
        $api.get(`/getCardsGroup/${id}`, {
            headers: {
              'Cache-Control': 'no-cache'
            }}).then((result) => {
            setCardsInfo(result.data.cardsList);
            console.log("Обновил список карточек", result.data.cardsList);
        });
    },[stateGroupCards]);

    useEffect(()=> {
        console.log("Dictionary. Изменился стейт", stateDictionary);
    }, [stateDictionary])


    const addGroupToDictionary = (groupId) => {
        $api.post(`/addGroupToDictionary/${groupId}`)
        .then(result => {
            if (result.status == 200) {
                console.log("Dictionary. Получен словарь:", result.data);
                dispatch(setDictionary(result.data));
                addMessage({type: "success", text: "Добавлено в словарь"});
            }
        })
        .catch(error => {
            const text = error.response?.data?.message ? error.response.data.message : error.message;
            addMessage({type: "error", text: text})
        });
    }

    return (
        <>
            {groupInfo && cardsInfo ? (
                <div className="cards-group">
                                    {/* <AddCard id={id} open={openModal}/> */}
                                    <ModalPopup openMod={openModal} toggleModal ={() => toggleModal(openModal, setOpenModal)} title={modalComponent?.title}>{modalComponent?.component}</ModalPopup>
                    <div className="cards-group--wrapper">
                        <div className="cards-group--content">
                            <div className="group-info">
                                <div>
                                    <div className="group-level"><span>{groupInfo.level}</span></div>
                                    <div className="group-name"><span>{groupInfo.group_name}</span></div>
                                    {(user.isAuth) && (
                                        <div className="group-btn blue-btn" onClick={() => {setModalComponent({component: <AddCardForm groupId={id} />, title: 'Добавить карточку'});toggleModal(openModal, setOpenModal)}}>Добавить карточку</div>
                                    )}

                                    {(user.isAuth) && (
                                        <div className="group-btn blue-btn" onClick={() => {setModalComponent({component: <EditGroupForm groupInfo={groupInfo} />, title: 'Редактировать набор'});toggleModal(openModal, setOpenModal)}}>Редактировать</div>
                                    )}

                                    {(user.isAuth) ? stateDictionary.groups.findIndex(x => x.group_id == id) !== -1 ? (
                                        <div className="group-btn green-btn no-interaction">Уже в словаре</div>
                                    ) : (<div className="group-btn blue-btn" onClick={() => addGroupToDictionary(id)}>Добавить в словарь</div>)
                                    : null}
                                    
                                </div>
                                <div className="group-description"><span>{groupInfo.group_description}</span></div>
                                <div className="trainings">Тренировки: 
                                    <Link to={`/training/learning/${id}`}><span>Повторение</span></Link>
                                    <Link to={`/training/word-translation/${id}`}><span>Слово-перевод</span></Link>
                                    <Link to={`/training/translation-word/${id}`}><span>Перевод-слово</span></Link>
                                </div>
                                <div className="tests">Тесты:
                                    <Link to={`/tests/sprint/${id}`}><span>Спринт</span></Link>
                                    <Link to={`/tests/comparison/${id}`}><span>Сопоставление</span></Link>
                                    <Link to={`/tests/selection/${id}`}><span>Подбор</span></Link>
                                </div>
                            </div>
                            <CardsList cardsList={cardsInfo}/>
                        </div> 
                    </div>
                </div>
            ) : (
                <div className="cards-group">
                <div className="cards-group--wrapper">
                    <div className="cards group--content">
                        <div>Загрузка...</div>
                    </div> 
                </div>
            </div>
            )}
        </>
    );
}

export default GroupCards;