
const dictionaryInitialState = {
    groups: [],
    cards: [],
    isLoaded:false,
}

const dictionaryReducer = (state = dictionaryInitialState, action) => {
    switch(action.type) {
        case "SET_DICTIONARY":
            console.log("Dictionary. Обработка данных в Redux:", action);
            return {
                ...state,
                groups: action.payload.groups,
                cards: action.payload.cards,
                isLoaded: true
            }

        default: return state;
    }
};

export default dictionaryReducer;