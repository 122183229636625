import logo from './logo.svg';
import React from 'react';
import {Header, Training, GroupCards} from './components';
import {Home, Library, Dictionary, SignIn, AdminPanel} from './pages';
import { Switch, Route, Routes, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {userLogin} from './redux/actions/user.js';
import StatisticService from "./services/statistic-service";
import FrameTraining from './pages/FrameTraining';

function App() {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const location = useLocation();
    
    const [user, setUser] = React.useState(null);

    
    React.useEffect(() => {
        const userLocStorage = localStorage.getItem('userData');
        const userData = (userLocStorage && userLocStorage !== 'undefined') ? JSON.parse(userLocStorage) : null;
        if (userData) {
            const accessToken = localStorage.getItem('accesToken');
            dispatch(userLogin(userData));
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
    }, []);

    if (location.pathname.startsWith("/embed/training")) {
        require('./styles.css');
        return <FrameTraining type="word-translation" id="321"/>;
    }

    if (location.pathname.startsWith("/admin-p")) {
        require('./admin.css');
        return <AdminPanel />
    }
    else {require('./styles.css');}
  return (
    <>
<Header/>

<content>
    <left-column>
        <div className="sidebar sidebar--wrapper">
            <div className="sidebar--content">
                <div className="items">
                    <div className="items-group">
                        <div className="items-title"><i className='bx bx-brain'></i><a><span>Тренировки</span></a></div>
                        <div className="items-list">
                            <div className="item">Повторение</div>
                            <div className="item">Слово-перевод</div>
                            <div className="item">Перевод-слово</div>
                            <div className="item">Аудирование</div>
                            <div className="item">Викторина</div>
                        </div>
                    </div>
                    <div className="items-group">
                        <div className="items-title"><i className='bx bx-task'></i><a><span>Тесты</span></a></div>
                        <div className="items-list">
                            <div className="item">Спринт</div>
                            <div className="item">Сопоставление</div>
                            <div className="item">Подбор</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </left-column>

    <right-column>
        <div className="content--wrapper">
            <div className="content">
            <Routes>
                <Route path='/training/word-translation/:id' element={<Training type="word-translation"/>} />
                <Route path='/training/translation-word/:id' element={<Training type="translation-word"/>} />
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<SignIn />} />
                <Route path="/group/:id" element={<GroupCards groups={[]}/>} />
                <Route path="/library" element={<Library />} />
                <Route path="/dictionary" element={<Dictionary />} />
            </Routes>
                    
            </div>
        </div>
    </right-column>
</content>
<footer></footer>
</>
  );
}

export default App;
