import React, {useState} from "react";
import {GroupsCard, CardsList} from "../components/";
import CheckUserRole from "../components/service-components/CheckUserRole";
import { useSelector, useDispatch } from 'react-redux';
import $api from '../http/api';
import { setDictionary } from '../redux/actions/dictionary';

function Dictionary() {

    const dispatch = useDispatch();
    const stateDictionary = useSelector((state) => state.dictionary);

    React.useEffect(() => {
        if (!stateDictionary.isLoaded) {
            $api.get("/getUserDictionary").then((result) => {
                dispatch(setDictionary(result.data));
            });
        }
    }, []);

    return (
        <div className="library">
            <div className="library--wrapper">
                <div className="library--content">
                    <div className="head">
                        <div className="title"><span>Ваши наборы карточек</span></div>
                    </div>
                    <GroupsCard data={stateDictionary.groups}/>
                    <div className="head">
                        <div className="title"><span>Ваш словарь</span></div>
                    </div>
                    <CardsList cardsList={stateDictionary.cards}/>
                </div>
            </div>
        </div>
    );
}

export default CheckUserRole(Dictionary, { isPage: true }, ["user", "admin"]);