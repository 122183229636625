import React, {useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import CheckUserRole from "../components/service-components/CheckUserRole";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import $api from '../http/api';
import {setGroupsCards} from '../redux/actions/cards.js';
import { setDictionary } from '../redux/actions/dictionary';

function GroupsCard({data = null}) {
    const [groupsData, setGroupsData] = useState([]);
    const currentPage = useLocation();

    const dispatch = useDispatch();
    const stateGroups = useSelector((state) => state.groupCards.groups);

    const stateDictionary = useSelector((state) => state.dictionary);

    React.useEffect(() => {
        if (!stateDictionary.isLoaded) {
            $api.get("/getUserDictionary").then((result) => {
                dispatch(setDictionary(result.data));
            });
        }
    }, []);

    React.useEffect(() => {
        if (!data) {
            if(stateGroups.length === 0){
                $api.post("/getGroupsCards").then((result) => {
                    dispatch(setGroupsCards(result.data.groups_card));
                    setGroupsData(result.data.groups_card)});
            }
            else {setGroupsData(stateGroups)}
        }
        else {
            setGroupsData(data);
        }
    }, [data]);

    return (
        <div className="groups--list">
            {groupsData.map((group) => (
            <Link to={`/group/${group.group_id}`}>
                <div className="group--item" style={{backgroundImage: `url(${group.img})`}}>
                    <div className="name"><span>{group.group_name}</span></div>
                    {stateDictionary.groups.findIndex(x => x.group_id == group.group_id) !== -1 && currentPage.pathname == `/library` && 
                    <div className="in-dictionary"><i class='bx bxs-bookmark'></i></div>}
                </div>
            </Link>
            ))}
        </div>
    );
}

export default GroupsCard;