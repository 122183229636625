const userDataInitialState = {
    userData: {},
    isAuth:false
}

const userReducer = (state = userDataInitialState, action) => {
    switch(action.type) {
        case "USER_LOGIN":
            return {
                ...state,
                isAuth: true,
                userData: action.payload
            }
        case "USER_LOGOUT":
            return {
                ...state,
                isAuth: false,
                userData: {}
            }
        default: return state;
    }
};

export default userReducer;