import axious from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const $api = axious.create({
    withCredentials: true,
    baseURL: API_URL
});

console.log("API_URL", API_URL);

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
})

export default $api;