import React from "react";
import { Link } from "react-router-dom";

function TopMenu({items, onClick}) {
    const [state, setActiveItem] = React.useState(null);
    return (
        <div className="menu--wrapper">
            <div className="menu">
                <div className="menu--content">
                    {items.map((item, index) => (
                    <div key={item} onClick={() => setActiveItem(index)} className={"menu--item" + (index === state ? " active" : "")}><Link to={item.link}><span>{item.title}</span></Link></div>)
                    )}
                </div>
            </div>
        </div>
    )
}

export default TopMenu;