import React, { useState, useRef, useEffect } from "react";
import $api from "../http/api";

function EditCardForm({ cardData }) {

    const [previewUrl, setPreviewUrl] = useState(null);
    const [previewAudio, setPreviewAudio] = useState(null);
    const imgInputRef = useRef(null);
  
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const imageRef = useRef(null);
    const audioRef = useRef(null);
    const wrapperRef = useRef(null);
    const [bounds, setBounds] = useState(null);
    const formRef = useRef(null);
    const [formMessage, setformMessage] = useState('');
  
    const [formData, setFormData] = useState(cardData);
  
    useEffect(() => {
        setFormData(cardData);
        setPreviewUrl(cardData.img);
    },[cardData])
  
      const startDrag = (e) => {
        e.preventDefault();
        setDragging(true);
      };
  
      const onDrag = (e) => {
        if (!dragging) return;
        if (!bounds) {
            setBounds(getBounds());
        }

        const deltaX = e.movementX;
        const deltaY = e.movementY;
      
        let withinHorizontalBounds, withinVerticalBounds;
        const newX = position.x + deltaX;
        if (bounds.image.width <= bounds.wrapper.width) {
          withinHorizontalBounds = bounds.image.left + deltaX > bounds.wrapper.left && bounds.image.right + deltaX < bounds.wrapper.right;
        }
        else {
          withinHorizontalBounds = bounds.image.left + deltaX < bounds.wrapper.left && bounds.image.right + deltaX > bounds.wrapper.right;
        }
        const x = withinHorizontalBounds ? newX : position.x;
  
      
        const newY = position.y + deltaY;
         if (bounds.image.top > bounds.wrapper.top && bounds.image.bottom < bounds.wrapper.bottom) {
          withinVerticalBounds = bounds.image.top + deltaY > bounds.wrapper.top && bounds.image.bottom + deltaY < bounds.wrapper.bottom;
        }
        else {
          if (bounds.image.top + deltaY > bounds.image.top) {
            withinVerticalBounds = bounds.image.top + deltaY < bounds.wrapper.top;
          }
          else {
            withinVerticalBounds = bounds.image.bottom + deltaY > bounds.wrapper.bottom;
          } 
        }
        const y = withinVerticalBounds ? newY : position.y;
        setPosition({ x, y });
      };
      
    
      const stopDrag = () => {
        setDragging(false);
      };
  
      const handleWheel = (e) => {
        e.preventDefault();
        const direction = e.deltaY < 0 ? 1 : -1;
        const newScale = scale + direction * 0.1;
        setScale(Math.min(Math.max(newScale, 0.5), 4));
      };
  
      function getBounds() {
        const wrapper = wrapperRef?.current;
        const image = imageRef?.current;
    
        if (wrapper && image) {
          const wrapperBounds = wrapper.getBoundingClientRect();
          const imageBounds = image.getBoundingClientRect();
          wrapperBounds.centerX = wrapperBounds.width/2 + wrapperBounds.left;
          wrapperBounds.centerY = wrapperBounds.height/2 + wrapperBounds.top;

          imageBounds.centerX = imageBounds.width/2 + imageBounds.left;
          imageBounds.centerY = imageBounds.height/2 + imageBounds.top;

          imageBounds.dcx = imageBounds.centerX - wrapperBounds.centerX;
          imageBounds.dcy = imageBounds.centerY - wrapperBounds.centerY;
          return {
            wrapper: wrapperBounds,
            image: imageBounds,
          };
        } else {
          return null;
        }
      }
  
  React.useEffect(() => {
    const imageElement = imageRef.current;
    if (!imageElement) return;
    if(!bounds && formData?.img_data) {
      const wrapper = wrapperRef?.current;
      const wrapperBounds = wrapper.getBoundingClientRect();
      const wrapperCenterX = wrapperBounds.width/2 + wrapperBounds.left;
      const wrapperCenterY = wrapperBounds.height/2 + wrapperBounds.top;
      const imageCenterX = formData.img_data.img_dcx + wrapperCenterX;
      const imageCenterY = formData.img_data.img_dcx + wrapperCenterY;
      const imageX = (imageCenterX - formData.img_data.img_w/2) - wrapperBounds.left;
      const imageY = (imageCenterY - formData.img_data.img_h/2) - wrapperBounds.top;
        setPosition({ x: imageX, y: imageY });
        imageRef.current.style.height = formData.img_data.img_h + "px";
        imageRef.current.style.width = formData.img_data.img_w + "px";
    }
    setScale(1);
    imageElement.addEventListener('wheel', handleWheel, { passive: false });
  }, [previewUrl]);
  
    React.useEffect(() => {
      setBounds(getBounds());
    }, [position, scale]);
  
    React.useEffect(() => {
      const imageElement = imageRef.current;
      if (!imageElement) return;
      imageElement.addEventListener('wheel', handleWheel, { passive: false });
  
      return () => {
        if (imageElement) { 
          imageElement.removeEventListener('wheel', handleWheel);
        }
      };
    }, [scale]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }; 
  
    const handleImgChange = (e) => {
      const selectedImg = e.target.files[0];
      if (selectedImg) {
        setFormData((prevData) => ({
          ...prevData,
          img: selectedImg,
        }));

        imageRef.current.style.height = "auto";
        imageRef.current.style.width = "100%";

        setPreviewUrl(URL.createObjectURL(selectedImg));
        console.log("загрузка картинки");
      }
    };
  
    const handleAudioChange = (e) => {
      console.log("handleAudioChange");
      const selectedAudio = e.target.files[0];
      if (selectedAudio) {
        setFormData((prevData) => ({
          ...prevData,
          sound: selectedAudio,
        }));
        setPreviewAudio(URL.createObjectURL(selectedAudio));
      }
    };
  
    const handleImgButtonClick = () => {
      imgInputRef.current?.click();
    };
  
    const handleAudioButtonClick = () => {
      audioRef.current?.click();
    };
  
    React.useEffect(() => {
      console.log("FormData from AddCard ", formData);
    }, [formData]);
  
    const clickPlayPrevewAudio = () => {
      if (!previewAudio) return;
      const audio = new Audio;
      audio.src = previewAudio;
      audio.play();
    }
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const formdata = new FormData();
        for (let key in formData) {
            formdata.append(key, formData[key]);
        }
        if (formdata.has("img_data")) {
          formdata.delete("img_data");
          formdata.append('img_data', JSON.stringify({img_w: bounds.image.width, img_h: bounds.image.height, img_dcx: bounds.image.dcx, img_dcy: bounds.image.dcy}));
        }
        else {
          formdata.append('img_data', JSON.stringify({img_w: bounds.image.width, img_h: bounds.image.height, img_dcx: bounds.image.dcx, img_dcy: bounds.image.dcy}));
        }
        
      $api.post(`/editCard/`, formdata)
        .then((response) => {
          console.log('Успешно отправлено:', response.data);
          setformMessage("Изменения сохранены");
        })
        .catch((error) => {
          console.error('Ошибка при отправке данных:', error);
        });
    };
  
    return (
      <div className="card" style={{ display: "flex", justifyContent: "center" }}>
                          <div className="left-side" style={{ position: "relative" }}>
                      <div className="card--content" style={{width: "100%"}}>
                          <div className="card-img--wrapper">
                              <div className="card-img--content" ref={wrapperRef} style={{ backgroundImage: "url('https://pic.rutubelist.ru/video/37/da/37da33522ccfda271da087e229ae17e6.jpg')", overflow: "hidden"}}>
                              {previewUrl && (
        <div>
        <img alt="preview" ref={imageRef} src={previewUrl} style={{
          height: "auto", 
          width: "100%", 
          objectFit: "cover",
          top: (position.y || 0) + "px", 
          left: (position.x || 0) + "px",
          position: "relative",
          transform: `scale(${scale})`,
          transformOrigin: 'center center'
        }} onMouseDown={startDrag}
        onMouseMove={onDrag}
        onMouseUp={stopDrag}
        onMouseLeave={stopDrag}
        draggable={false}
        />
      </div>
        )}
                              </div>
                          </div>
                          <div className="card-information">
                              <div className="word"><span>{formData.word || "Слово"}</span></div>
                              <div className="transcription"><span>{"[" + formData.transcription + "]" || "Транскрипция"}</span></div>
                              <div className="word-sound">
                                  <i className='bx bx-volume-full' onClick={clickPlayPrevewAudio}></i>
                              </div>
                              <div className="translate"><span>{formData.translate || "Перевод"}</span></div>
                          </div>
                      </div>
                          </div>
                          <div className="right-side" style={{ flexBasis: "0%" }} ref={formRef}>
                          <form onSubmit={handleSubmit}>
          <div className="form--item">         
            <label>Картинка</label>
            <button onClick={handleImgButtonClick} type="button">Загрузить картинку</button>
            <input
              type="file" name="img" style={{ display: "none" }} ref={imgInputRef} onChange={handleImgChange} accept="image/*,image/jpeg"
            />
        </div>
        <div className="form--item">
          <label>Слово на иврите</label>
          <input
            type="text" name="word" value={formData.word}onChange={handleChange} required
          />
        </div>
        <div className="form--item">
          <label>Перевод</label>
          <input
            type="text" name="translate" value={formData.translate} onChange={handleChange} required
          />
        </div>
        <div className="form--item">
          <label>Транскрипция</label>
          <input type="text" name="transcription" value={formData.transcription} onChange={handleChange} required
          />
        </div>
        <div className="form--item">         
            <label>Аудио</label>
            <button onClick={handleAudioButtonClick} type="button">Загрузить озвучку</button>
            <input
              type="file" name="audio" style={{ display: "none" }} ref={audioRef} onChange={handleAudioChange} accept="audio/*,audio/mp3,audio/waw"
            />
        </div>
        <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                  <button type="submit">Сохранить изменения</button>
                  <div className="form--message">{formMessage}</div>
                </div>
      </form>
                          </div>

      </div>
    );
  }

  export default EditCardForm;