import React, { useEffect } from "react";
import ModalPopup, {toggleModal} from "./ModalPopup.jsx";
import EditCardForm from "./EditCardForm.jsx";
import { useSelector, useDispatch } from 'react-redux';
import { setUserStatCards } from '../redux/actions/stat-cards';
import $api from '../http/api.js';

function CardsList({cardsList}) {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [audio, setAudio] = React.useState(null);
    const [audioData, setAudioData] = React.useState(null);

    const dispatch = useDispatch();

    const stateDictionary = useSelector((state) => state.dictionary);
    const stateStatCards = useSelector(state => state.statCards);

    React.useEffect(() => {
        if (!stateStatCards.isLoaded) {
            $api.post('/user/getUserStatCards').then((result) => {
                dispatch(setUserStatCards(result.data.cardsStat));
            });
        };

        const newAudio = new Audio();
        setAudio(newAudio);
    
        const handlePlay = () => {
            setIsPlaying(true);
        };
    
        const handlePause = () => {
          setIsPlaying(false);
        };
    
        // Добавляем обработчики событий для аудио
        if (newAudio) {
          newAudio.addEventListener('play', handlePlay);
          newAudio.addEventListener('pause', handlePause);
        }
    
        return () => {
          // Удаляем обработчики событий при размонтировании компонента
          if (newAudio) {
            newAudio.removeEventListener('play', handlePlay);
            newAudio.removeEventListener('pause', handlePause);
            newAudio.pause();
          }
          setAudio(null);
        };
      }, []);

      useEffect(() => {
          console.log("stateStatCards", stateStatCards);
      }, [stateStatCards]);

    const[openModal, setOpenModal] = React.useState(false);
    const [modalComponent, setModalComponent] = React.useState(null);

    const handleEditCard = (e) => {
        const card = e.target.closest(".cards--item");
        const word = card.dataset.cardWord;
        const cardData = cardsList.find(card => card.word == word);
        console.log(cardsList.find(card => card.word == word));
        setModalComponent({component: <EditCardForm cardData={cardData} />, title: 'Изменить карточку'});
        toggleModal(openModal, setOpenModal);
    };

    function playingSound(srcAudio, word) {
        audio.src = srcAudio;
        if (audio) {
            if (isPlaying) {
              audio.pause();
              setIsPlaying(false);
            } else {
                setAudioData({word: word});
                audio.play();
            }
          }
    };

    function countProgress(cardObj){
        if (!cardObj) return 0;
        const balls=cardObj?.trainings
        .map(t=> {
            for (const [key, value] of Object.entries(t)) {
                return value.success > 4 ? 4 : value.success;
            }
        })
        .reduce((a, b) => a + b, 0);
        return balls/8*100;
    }

    return (
        <div className="cards">
            <ModalPopup openMod={openModal} toggleModal ={() => toggleModal(openModal, setOpenModal)} title={modalComponent?.title}>{modalComponent?.component}</ModalPopup>
            <div className="cards--content">
                <div className="cards-list">
                    {cardsList.map((card, index) => (
                        <div className="cards--item" data-card-word={card.word} key={card.word}>
                            <div className="cards--item--table">
                                <div className="index"><span>{index +1}</span></div>
                                <div className="img">
                                    <div className="img--content" style={{ backgroundImage: `url(${card.img})`}}></div>
                                </div>
                                <div className={"word-sound" + (isPlaying && (audioData.word == card.word) ? " play" : "")} onClick={()=> playingSound(card["sound"], card.word)}>
                                    <i className={`bx bx-volume-${card["sound"] ? "full" : "mute"}`}></i></div>
                                <div className="word"><span>{card.word}</span></div>
                                <div className="transcription"><span>{ "[" + card.transcription + "]"}</span></div>
                                <div className="translate"><span>{card.translate}</span></div>
                                <div className="progress">
                                    <div class="progress-wrap">
                                        <svg>
                                            <circle cx="10" cy="10" r="10"></circle>
                                            <circle cx="10" cy="10" r="10" style={{strokeDashoffset: 63 - 63 * countProgress(stateStatCards.cardsData?.cards?.find(c=> c.card_id == card.card_id)) / 100}}></circle>
                                        </svg>
                                    </div>
                                </div>
                                <div className="edit-btn" onClick={handleEditCard}><i class='bx bx-edit'></i></div>
                            </div>
                            {card.group_ids && stateDictionary.groups.length && (
                                <div className="groups"><span>{card.group_ids.length ? 'Содержится в наборах: ' : 'Без набора'}</span>{card.group_ids.map(group => { 
                                    const findGroup = stateDictionary.groups.find(g => g.group_id === group);
                                    return <a href={`/group/${findGroup.group_id}`}>{findGroup.group_name} </a> 
                                })}</div>
                            ) }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CardsList;