import React from "react";

export default function ModalPopup({children, openMod, toggleModal, title}) {
    const [openModal, setOpenModal] = React.useState(openMod);

    React.useEffect(() => {
        setOpenModal(openMod);
    }, [openMod]);

    return (
        <div className={openModal ? `modal-block visible` : `modal-block`}>
            <div className="popup--wrapper">
                <div className="popup--content">
                    <div className="popup--header">
                        <h3>{title}</h3>
                        <i className='bx bx-x' onClick={() => toggleModal()}></i>
                    </div>
                    <div className="popup--body">
                        {React.Children.map(children, child => {
                            return React.cloneElement(child, { openModal: openModal, key: openModal });
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function toggleModal (openModal, setOpenModal) {
    setOpenModal(prevState => !prevState);
    }