import React from 'react';
import ReactDOM from 'react-dom';
import {MessageProvider} from './components/service-components/MessageContext';
import {Provider} from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from 'react-router-dom';
import axios from 'axios';

import store from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log("state", store.getState());
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <MessageProvider>
          <App />
        </MessageProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
