import React from 'react';
import TopMenu from './TopMenu.jsx';
import {userLogout} from '../redux/actions/user.js';
import { useSelector, useDispatch } from 'react-redux';

function Header(props){
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    console.log("userData ", user.userData);
    function logout() {
        dispatch(userLogout());
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
    }
    return (
        <header>
        <div className="logo"></div>
        <TopMenu onClick={(item) => console.log(item)} items={[{title: 'Главная', link: '/'}, {title: 'Библиотека', link: '/library'}, {title: 'Тренировки', link: '/trainings'}, {title: 'Мой словарь', link: '/dictionary'}, {title: 'Курсы', link: 'https://korni.co.il'}]}/>
        <div className="search">
            <div className="search-content">
                <i className='bx bx-search'></i>
                <input type="text" placeholder="Поиск" />
            </div>
        </div>
        <div className="profile--wrapper">
            <div className="profile">
                <div className="profile--content"><span>{user.userData.email ? user.userData.email : 'Гость'}</span></div>
            </div>
        </div>
        <div className="notification"></div>
        <div className="logout" onClick={() => logout()}><i className='bx bx-log-out-circle bx-rotate-180'></i></div>
        </header>
    )
}

export default Header;